<template>
  <div
    style="width: 100%;height: 100%"
    class="d-flex justify-center"
  >
    <v-img
      v-if="isStickerPrinter"
      :src="printerImage"
      :height="height"
    />
    <v-img
      v-else-if="isSunmiPrinter"
      :src="printerImage"
      width="70%"
      contain
      :height="height"
      style="flex-grow: 0;"
    />
    <v-card
      v-else-if="printerTypeId===-1"
      flat
      :height="height"
      color="transparent"
      class="d-flex align-center justify-center"
    >
      <v-icon
        :size="64"
      >
        mdi-plus
      </v-icon>
    </v-card>

    <v-img
      v-else
      :src="printerImage"
      contain
      :height="height"
      style="flex-grow: 0"
      width="60%"
    />
  </div>
</template>

<script>
import { printerIsStickerPrinter } from '@/model/dataLayer/api/print/managedDevice'

export default {
  name: 'PrinterImage',
  props: {
    printerTypeId: {},
    height: {
      default: '196'
    }
  },
  computed: {
    isStickerPrinter () {
      return printerIsStickerPrinter(this.printerTypeId)
    },
    isSunmiPrinter () {
      return this.printerTypeId === 8
    },
    printerImage () {
      if (this.isSunmiPrinter) {
        return require('@/assets/printers/sunmiPrinter.jpeg')
      } else if (this.isStickerPrinter) {
        return require('@/assets/printers/usb-sticker.png')
      } else if (this.printerTypeId === -1) {
        return ''
      } else {
        return require('@/assets/printers/epson-tm30.png')
      }
    }
  }
}
</script>

<style scoped>

</style>
