import DeviceList from '@/model/dataLayer/api/print/DeviceList'
import PrinterGroup from '@/model/dataLayer/api/print/PrinterGroup'

export async function loadDevices () {
  const printerList = await DeviceList.getList(true)
  const printerGroup = await PrinterGroup.getList(true)
  return printerGroup.filter(it => ![3, 10].includes(it.id)).map(it => {
    it.bindedPrinter = printerList.find(that => that.groupId?.includes(it.id))
    return it
  })
}

export function printerIsStickerPrinter (printerTypeId) {
  return [9, 10].includes(printerTypeId)
}
