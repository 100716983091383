<template>
  <printer-image :printer-type-id="printer.printerTypeId" />
</template>

<script>
import PrinterImage from '@/views/AadenPages/print/composable/PrinterImage.vue'

export default {
  name: 'PrinterDisplay',
  components: { PrinterImage },
  props: {
    printer: {}
  }
}
</script>

<style scoped>

</style>
